import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const WoodstovePage = () => (
  <Layout page="woodstove-page">
    <Seo title="WOODSTOVE"/>
    <section className="page-head">
      <ListBrand/>
      <ListLink/>
      <h2 className="main-title"><span>WOOD</span><span>STOVE</span></h2>
    </section>
    <section className="page-content flex-layout sp-read">
      <div className="main-content">
        <div className="content-block">
          <h3 className="sub-title"><span>薪ストーブとは</span></h3>
          <p>アンデルセンストーブや、薪ストーブの魅力を詳しくご説明します。</p>
          <Image className="w-full md:mt-16 mt-10" filename="woodstove/mv.jpg" />
          <div className="md:flex md:mt-16 mt-10">
            <div className="md:w-1/2 md:pr-10">
              <h3 id="andersen" className="text-3xl c-yellow tracking-widest">Andersen Stoveとは</h3>
              <p className="md:mt-9 mt-5">アンデルセンストーブとは個別のメーカー名ではなく㈱新宮商行が取り扱う薪ストーブの総称になります。</p>
              <p className="md:mt-10 mt-5">北欧の優美な曲線フォルムを持つ素晴らしい炎の芸術品を日本のご家庭にもお届けしたいという願いを胸に、㈱新宮商行は、1982年デンマークのＤＳＡ社（Danish Stove Association）のスチール製及び鋳物製薪ストーブの輸入販売を開始し1994年に鋳鉄製ストーブの製造販売において150年以上の歴史と拘りを持つトップメーカーであるデンマークのmorso社との輸入販売契約を取り交わすことによって、アンデルセンストーブの取扱いブランドを2つに増やしました。</p>
            </div>
            <div className="md:w-1/2 md:h-1/2 text-center py-14 bg-gray mt-5 md:mt-0">
              <Image className="image mx-auto" filename="logo/andersen.png" width={250} />
            </div>
          </div>
          <p className="md:mt-14 mt-5">
            その後2017年からは鋼板の本体と鋳物のドアのハイブリットストーブであるイギリスのaradaの取扱いを開始し、2021年からは工業大国ドイツにおいて150年の伝統と技術力が高く評価され品質の妥協が許されない交通インフラであるドイツ鉄道や市町村などの公的機関への納入実績を持つLEDAの取扱いを開始しました。<br />
            より良い薪ストーブを皆様にお届けするため現在はデンマークのみならず世界中の薪ストーブから選りすぐりのモデルを供給しています。
          </p>
          <div className="md:flex mt-9">
            <Image className="md:w-1/2 full-image" filename="woodstove/andersen-1.jpg" />
            <Image className="md:w-1/2 full-image" filename="woodstove/andersen-2.jpg" />
          </div>
          <p className="md:mt-14 mt-5">アンデルセンストーブは日本をはじめ、世界各国に市場を拡大して行くと同時に、環境問題やエネルギーに対する関心やニーズも高まりつつある今日、様々な取り組みを展開しております。</p>
          <h3 className="text-2xl c-gray tracking-widest md:mt-14 mt-10">環境に優しい最新技術</h3>
          <div className="md:flex md:flex-row-reverse md:mt-9 mt-5">
            <div className="md:w-1/2 mt-10 md:mt-0">
              <Image className="image full-image" filename="woodstove/andersen-3.jpg" />
            </div>
            <div className="md:w-1/2 md:pr-10 md:mt-0 mt-5">
              <p>ひとつは、カーボンニュートラルでグリーンエネルギー資源のである薪が持つ潜在能力をフルに引き出す為、触媒（キャタリティック・コンバスター）を一切使用しない、経済的で人や環境にも優しい燃焼工学技術を盛り込んだ「クリーンバーン・システム」や「LEDA O2ACTIVE SYSTEM」を採用することにより、欧州や北米などで規定されている環境認定基準等をクリアーしクリーンな燃焼を実現しました。</p>
              <Link to="/brand/morso/about/#system" className="text-link en white py-0 md:mt-14 mt-8">
                クリーンバーン・システム
              </Link>
              <Link to="/brand/leda/about/#system" className="text-link en white py-0 md:mt-14 mt-8">
                LEDA O2ACTIVE SYSTEM
              </Link>
            </div>
          </div>
          <p className="md:mt-14 mt-10">もうひとつは、自国の著名デザイナー陣を積極的の起用し、デザイン性のみならず、機能性と使い勝手の良さ、更に安全性についても技術陣スタッフらとともに製品開発を行っております。最後に、鋳鉄ストーブに使用される鋳物部品のほぼ１００％近くがリサイクル品であり、生産工場に必要な膨大な量のエネルギーの多くは水力発電や風力発電などのクリーンエネルギーによって賄われています。</p>
        </div>

        <div id="wood" className="content-block">
          <h3 className="text-3xl c-yellow tracking-widest">薪ストーブについて</h3>
          <p className="mt-5">
            薪ストーブは欧米諸国など寒さの厳しい地域で使用されるのが一般的でした。日本においても北海道や東北などの寒い地域、または別荘に取り付けるなどのイメージがあるかもしれませんが現在は北海道から九州まで日本全国で使用されており新築住宅に限らず既存住宅にも取り付けは可能です。<br />
            薪ストーブの特徴は何と言っても燃料が薪であることです。石油などの化石燃料に比べて木は早く成熟して燃料として使用することができ持続可能な燃料と言えます。また薪を燃やして発生する二酸化炭素量はその薪がこれまでに吸収した二酸化炭素量になり地球上の二酸化炭素量を増加させないというカーボンニュートラルの概念がありエコな暖房器具とも言えます。
          </p>
          <Image className="w-full md:mt-14 mt-10" filename="woodstove/wood-1.jpg" />
          <p className="md:mt-14 mt-10">
            もちろんエコなだけでなく暖房器具としても優秀です。薪ストーブの放つ遠赤外線は、石油ストーブやファンヒーターと比べてかなり強く、体の奥深くにじんわり伝わってきます。<br />
            また、特に温風を強制的に発することもありませんので、静かでありながらも強力な暖かさを享受できます。
          </p>
          <div className="md:flex md:mt-14 mt-10">
            <div className="md:w-1/2 md:pr-10">
              <h3 className="text-2xl c-gray tracking-widest">
                炎を見る楽しみというのも<br />
                薪ストーブを語る上では欠かせません。
              </h3>
              <p className="md:mt-9 mt-5">
                炎を見る楽しみというのも薪ストーブを語る上では欠かせません。
                薪ストーブの燃える炎は絶えず揺らめき、時間の移ろいとともにその姿を変えていく姿は、まるで生き物のようです。
              </p>
              <p className="md:mt-10 mt-5">
                つい時間を忘れ火に見入ることもしばしば。ワインを飲んで家族と語らうのも一興です。一昔前はテレビがリビングの中心にありましたが、薪ストーブが来たその日から、そのポジションを取って代わられるかもしれません。
              </p>
              <p className="md:mt-0 mt-5">また多くのユーザー様が楽しんでいるのは料理です。薪ストーブは一度火を入れるとかなりの熱源になります。炉内でピザを焼いたり、肉を焼いたり、天板でダッチオーブンを温めたり、様々な調理を愉しめます。</p>
              <h3 className="text-2xl c-gray tracking-widest mt-10">
                暖房としての実用性が高く、<br />
                炎を見て安らぐことができ、<br />
                調理も愉しめる・・・
              </h3>
              <p className="md:mt-9 mt-5">
                そして何より、家族の絆を深め、それぞれの心の拠り所となるのが、薪ストーブのある暮らしではないでしょうか。どんな仕事人間でも早く家に帰りたくなる、そんな風にしてくれるのが、薪ストーブの魔力であり、魅力なのかもしれません。
              </p>
            </div>
            <div className="md:w-1/2 mt-10">
              <Image className="full-image" filename="woodstove/wood-2.png" />
            </div>
          </div>
          <p className="md:mt-14 mt-10">
            よく薪ストーブと暖炉の違いは何かと言われますが、基本的に暖炉の構造は開放式となっており、屋内で焚き火の雰囲気を鑑賞する趣向で作られているため、暖が取れる範囲としては暖炉本体の周辺部分だけです。その為、大きな部屋では暖炉から離れるとあまり暖かさが感じられません。
          </p>
          <p className="md:mt-10 mt-5">一方の密閉構造の薪ストーブは本体の暖房能力と暖めようとする部屋の空間の大きさにもよりますが、輻射熱や対流熱を発することによって部屋全体の空間を暖めることができるので、薪ストーブを焚いている部屋に居れば、本体から離れていても暖かく感じられます。</p>
          <p className="md:mt-10 mt-5">輻射熱は薪が燃焼しているストーブ本体の表面部分からだけでなく、煙突の表面部分からも発生しているので、より効率よく部屋を暖めることができ、更なる暖房効果が期待できます。</p>
          <div className="md:flex mt-12">
            <Link to="/brand/morso/about/" className="block text-center md:w-1/2 md:mr-6 border py-10 md:hover:opacity-70 transition-opacity">
              <Image className="image mx-auto md:w-3/12 w-1/2" filename="logo/morso.png" />
            </Link>
            <Link to="/brand/leda/about/" className="block text-center md:w-1/2 md:ml-6 border py-12 mt-5 md:mt-0 md:hover:opacity-70 transition-opacity">
              <Image className="image mx-auto md:w-4/12 w-1/2" filename="logo/leda.png" />
            </Link>
          </div>
        </div>
      </div>
      <div className="side-list">
        <ul className="sticky">
          <li>
            <GoLink to="andersen" spy smooth duration={800}>
              アンデルセンストーブとは
            </GoLink>
          </li>
          <li>
            <GoLink to="wood" spy smooth duration={800}>
              薪ストーブについて
            </GoLink>
          </li>
        </ul>
      </div>
    </section>
    <section className="bread">
      <ul>
        <li>
          <Link to="/">
            <Image filename="ico/home.png" />
          </Link>
        </li>
        <li>薪ストーブとは</li>
      </ul>
    </section>
  </Layout>
);

export default WoodstovePage;
